import React from 'react';
import './index.scss';

export default function Page500() {
  return (
    <div id='error-boundary'>
      <div id="error">Error</div>
      <div className="error-num">
        500
        <div className="error-num__clip">500</div>
      </div>
      <p id="desc">Uh oh, there seems to be a problem.</p>
      <p>
        Let me help you find{' '}
        <a href="https://app.missionmed.com.au/" target="_blank" rel="noreferrer">
          a way out
        </a>
      </p>
    </div>
  );
}
